import styled from 'styled-components'
import { Img } from '../../common'

export const FeaturesContainer = styled.div`
  padding: 2% 0;
`

export const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: 46px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 767.98px) {
    font-size: 36px;
  }
`
export const FeaturesWrapper = styled.div`
  margin-top: 55px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around;
`

export const FeaturedBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  justify-items: center;
  text-align: center;
  margin: 50px 0;
  width: 280px;
  vertical-align: top;
  position: relative;
  @media (max-width: 600px) {
    margin: 25px 0;
  }
`

export const FeatureIcon = styled(Img)`
  top: 15px;
  height: 85px;
  width: 85px;
`
export const FeatureTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
`

export const FeatureTitle = styled.div`
  font-family: 'Exo 2', sans-serif;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 800;
  @media (max-width: 600px) {
    min-height: auto;
  }
`

export const FeatureText = styled.p`
  margin-top: 10px;
  text-align: center;
  display: block;
  color: #212121;
`
