import styled from 'styled-components'
import { Link } from '../../../common/link/Link'

export const FooterContainer = styled.div`
  background-color: #212121;
  color: #fff;
  font-size: 11px;
  padding: 2% 0;
  overflow: hidden;
  text-align: center;
  @media (max-width: 767.98px) {
    padding: 5% 0 5%;
  }
`

export const LinkLogo = styled(Link).attrs((p) => ({
  ...p.theme.localLink,
}))`
  cursor: pointer;
  display: inline-block;
  position: relative;
  max-width: 80px;
  width: 100%;
`

export const Copyright = styled.div`
  position: relative;
  margin-top: 15px;
`

export const FooterSocials = styled.div`
  margin-top: 35px;
`

export const LinkStyled = styled(Link).attrs((p) => ({
  ...p.theme.localLink,
}))`
  cursor: pointer;
  display: inline-block;
  margin: 10px 15px 0;
  max-width: 75px;
  width: 100%;
  position: relative;
  transition: filter 0.1s ease-in-out;
  filter: grayscale(100%);
  &:hover {
    transition: filter 0.1s ease-in-out;
    filter: grayscale(0%);
  }
  @media (max-width: 767.98px) {
    margin: 10px 5px 0;
    max-width: 60px;
  }
  @media (max-width: 575.98px) {
    max-width: 50px;
  }
`
