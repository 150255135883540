import styled from 'styled-components'

export const TeamContainer = styled.div`
  padding: 3% 0;
  text-align: center;
  display: block;
  @media (max-width: 991.98px) {
    padding: 5% 0;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

export const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: 46px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  @media (max-width: 767.98px) {
    font-size: 36px;
  }
`

export const Separator = styled.img`
  display: block;
  height: auto;
  margin: 10px auto 0;
  position: relative;
  width: auto;
`

export const TeamRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const TeamItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  max-width: 250px;
  margin: 0 15px;
  width: 100%;
`

export const MemberBlock = styled.div`
  margin: 30px 0 10px 0;
`

export const DiamondWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  height: 250px;

  width: 100%;
  @media (max-width: 991.98px) {
    height: 200px;
    max-width: 200px;
  }
`

export const DiamondBox = styled.div`
  transform: rotate(45deg);
  box-sizing: border-box;
  overflow: hidden;
  width: 70.710678118%;
  height: 70.710678118%;
  margin: 14.644660941%;
  border: 1px solid #000;
`

export const DiamondBoxInner = styled.div`
  transform: rotate(-45deg);
  cursor: pointer;
  width: 141.421356237%;
  height: 141.421356237%;
  margin: -20.7106781185% 0 0 -20.7106781185%;
`

export const MemberPhoto = styled.img`
  object-fit: contain;
  width: 100%;
  margin: 0 auto;
`

export const MemberPosition = styled.h3`
  color: #9ecf31;
  display: inline-block;
  font-family: 'Exo 2', sans-serif;
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 0;
  width: 100%;
  margin-top: 0;
  text-shadow: 1px 1px 0 #000;
  position: relative;
`

export const MemberName = styled.h3`
  font-family: 'Exo 2', sans-serif;
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 5px;
  margin-top: auto;
`
