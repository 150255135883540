import styled from 'styled-components'
import { Img } from '../../../../../common'

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  height: 50%;
  width: 50%;
  padding-bottom: 40px;
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
  }
`

export const Row = styled.div`
  position: relative;
  left: 48.5%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 700px) {
    flex-direction: column;
    left: auto;
  }
`

export const Mask = styled.div`
  transform: rotate(45deg);
  margin: -18px 40px;

  @media (max-width: 700px) {
    transform: none;
    margin: 0 auto;
  }
`

export const ItemWrapper = styled.div`
  overflow: hidden;
  height: 150px;
  width: 150px;

  @media (max-width: 700px) {
    height: 300px;
    width: 100%;
    max-width: 300px;
    margin: 5px auto;
  }
`

export const Item = styled(Img)`
  transform: rotate(-45deg) scale(1.5);
  width: 100%;

  @media (max-width: 700px) {
    transform: none;
  }
`
