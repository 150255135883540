import React from 'react'
import { range } from 'lodash-es'
import { Divider, Separator } from './styles'

export const SeparatorBlock = ({ color = 'black', amount = 3 }) => (
  <Separator color={color}>
    {range(amount).map((item, i) => (
      <Divider key={i}>■</Divider>
    ))}
  </Separator>
)
