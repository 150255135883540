import styled from 'styled-components'
import { Img } from '../../common'

export const Background = styled(Img)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const ContactContainer = styled.div`
  padding: 2% 0 0;
`

export const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: 46px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  @media (max-width: 767.98px) {
    font-size: 36px;
  }
`

export const ContactBlackDiamond = styled.div`
  background-color: #181619;
  color: #fff;
  height: auto;
  margin: 20% auto 0;
  max-width: 840px;
  min-height: 840px;
  overflow: hidden;
  position: relative;
  text-align: center;
  transform: rotate(45deg);
  width: 100%;
  z-index: 9;
  @media (max-width: 1199.98px) {
    max-width: 700px;
    min-height: 700px;
  }
  @media (max-width: 991.98px) {
    max-width: 545px;
    min-height: 545px;
  }
  @media (max-width: 767.98px) {
    max-width: 100%;
    transform: rotate(0deg);
    min-height: 765px;
    margin: 12% auto 0;
    overflow: visible;
  }
`

export const Description = styled.div`
  background-image: url(${(p) => p.rhomb.file.url});
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: -7%;
  height: auto;
  max-width: 390px;
  min-height: 390px;
  position: absolute;
  right: -7%;
  transform: rotate(-45deg);
  width: 100%;
  padding: 8%;
  @media (max-width: 1199.98px) {
    bottom: -9%;
    right: -9%;
  }
  @media screen and (max-width: 991.98px) {
    background-size: contain;
    bottom: -14%;
    display: block;
    font-size: 12px;
    max-width: 260px;
    min-height: 300px;
    right: -10.4%;
  }
  @media screen and (max-width: 767.98px) {
    bottom: -20.7%;
    margin-left: 0;
    max-width: 315px;
    margin-right: 0;
    position: absolute;
    min-height: 315px;
    padding: 5%;
    width: 100%;
    left: 50%;
    right: 0;
    transform: translate(-50%, 0%) !important;
  }
`

export const Email = styled.a`
  margin: 0;
  color: #fff;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`

export const Text = styled.div`
  p {
    margin: 0 0 10px;
  }
  b {
    margin-top: 20px;
    position: relative;
    font-weight: bold;
    @media screen and (max-width: 767.98px) {
      margin-top: 33%;
    }
  }
  u {
    text-decoration: none;
  }
  @media screen and (max-width: 991.98px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 767.98px) {
    margin-top: 85px;
  }
`
