import React from 'react'
import Zoom from 'react-reveal/Zoom'
import { SeparatorBlock } from '../../common/separator/Separator'
import {
  FeaturesContainer,
  Title,
  FeaturesWrapper,
  FeatureIcon,
  FeatureTitle,
  FeatureText,
  FeaturedBlock,
  FeatureTitleContainer,
} from './styles'
import { Centered, Section } from '../../../styles'

export const Skills = ({ skills, title }) => {
  return (
    <Section id='skills'>
      <FeaturesContainer>
        <Centered>
          <Title>{title}</Title>
          <SeparatorBlock />

          <FeaturesWrapper>
            {skills.map((item, index) => (
              <Zoom
                key={index}
                duration={600}
                delay={100 * index}
                fraction={0.1}
              >
                <FeaturedBlock>
                  <FeatureIcon src={item.image} />
                  <FeatureTitleContainer>
                    <FeatureTitle>{item.title}</FeatureTitle>
                  </FeatureTitleContainer>

                  <FeatureText>{item.technologies}</FeatureText>
                </FeaturedBlock>
              </Zoom>
            ))}
          </FeaturesWrapper>
        </Centered>
      </FeaturesContainer>
    </Section>
  )
}
