import React from 'react'
import makeCarousel from 'react-reveal/makeCarousel'
import { Slide } from 'react-reveal'
import {
  Container,
  ContentContainer,
  Arrow,
  Image,
  SlideImg,
  SlideDescription,
  SlideP,
  SlideTitle,
  ProjectSliderContainer,
} from './styles'
import { RichTextToHtml } from '../../../../../../helpers'

export const ProjectsTab = ({ projects, sliderArrow }) => {
  const CarouselUI = ({ position, handleClick, children }) => (
    <Container>
      {children}
      <Arrow
        onClick={handleClick}
        data-position={position - 1}
        src={sliderArrow}
      />
      <Arrow
        right
        onClick={handleClick}
        data-position={position + 1}
        src={sliderArrow}
      />
    </Container>
  )
  const Carousel = makeCarousel(CarouselUI)

  return (
    <ProjectSliderContainer>
      <Carousel defaultWait={7500} maxTurns={0}>
        {projects.map((item, index) => (
          <Slide key={index} right>
            <ContentContainer position={item.position}>
              <SlideImg>
                <Image src={item.image} />
              </SlideImg>
              <SlideDescription>
                <SlideTitle>{item.title}</SlideTitle>
                <SlideP {...RichTextToHtml(item.description)} />
                <SlideP {...RichTextToHtml(item.technologies)} />
              </SlideDescription>
            </ContentContainer>
          </Slide>
        ))}
      </Carousel>
    </ProjectSliderContainer>
  )
}
