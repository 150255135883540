import { useState, useCallback } from 'react'

export const useToggle = (init = false) => {
  const [value, setValue] = useState(init)

  const toggle = useCallback(() => {
    setValue((v) => !v)
  }, [])

  return [value, toggle, setValue]
}
