import { useState, useEffect } from 'react'

export const useDelayRender = (delay = 0) => {
  const [ready, setReady] = useState(() => delay <= 0) //ready if delay <= 0
  useEffect(() => {
    if (!ready) {
      const id = setTimeout(() => setReady(true), delay)
      return () => {
        clearTimeout(id)
      }
    }
  }, [])
  return ready
}
