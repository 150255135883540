import { ScrollImg, SwipeImg, ScrollTop, StyledLink } from './styles'
import React from 'react'

export const ScrollImage = ({ to, scroll, swipe }) => (
  <ScrollTop>
    <StyledLink to={to}>
      <ScrollImg src={scroll} alt='scroll-icon' />
      <SwipeImg src={swipe} alt='swipe-icon' />
    </StyledLink>
  </ScrollTop>
)
