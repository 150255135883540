import styled from 'styled-components'
import { Global } from './Global'

export const Centered = styled.div`
  z-index: 9;
  height: auto;
  margin: 0 auto;
  max-width: 1170px;
  padding: ${(p) => p.padding || '0 15px'};
  text-align: center;
  position: relative;
  width: 100%;
`

export const Section = styled.div`
  position: relative;
`

export { Global }
