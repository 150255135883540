import React from 'react'
import { ContactForm } from '../common/contactForm/ContactForm'
import { SeparatorBlock } from '../../common'
import {
  ContactContainer,
  Title,
  ContactBlackDiamond,
  Description,
  Email,
  Text,
  Background,
} from './styles'
import { Centered, Section } from '../../../styles'
import { RichTextToHtml } from '../../../helpers'

export const Contact = ({
  background,
  rhomb,
  description,
  title,
  email,
  webhook,
}) => (
  <Section id='contact'>
    <ContactContainer>
      <Background src={background} alt='background-img' />
      <Centered>
        <Title>{title}</Title>
        <SeparatorBlock />
        <ContactBlackDiamond>
          <ContactForm rhomb={rhomb} webhook={webhook} />
          <Description rhomb={rhomb}>
            <Text {...RichTextToHtml(description)} />
            <Email href={`mailto:${email}`}>{email}</Email>
          </Description>
        </ContactBlackDiamond>
      </Centered>
    </ContactContainer>
  </Section>
)
