import React, { useState, useEffect, useCallback } from 'react'
import { JobsForm } from '../common'
import { RichTextToHtml } from '../../../helpers'
import {
  JobsContainer,
  Title,
  JobsList,
  JobsListInner,
  JobItem,
  JobTitle,
  JobLocation,
  JobModalHeader,
  ModalHeaderBlock,
  JobModalInner,
  JobsText,
  ModalHeaderJobLocation,
  BackToJobs,
  modalStyles,
} from './styles'
import { Centered, Section } from '../../../styles'
import { SeparatorBlock } from '../../common'
import Modal from 'react-modal'

export const Vacancies = ({ vacancies, jobId, title, webhook }) => {
  const [isOpen, setIsOpen] = useState(() => jobId !== null)
  const [selectedJob, setSelectedJob] = useState(() =>
    vacancies.find((x, index) => index === jobId - 1)
  )

  const onOpen = () => {
    const id = vacancies.findIndex((x) => x.title === selectedJob.title) + 1
    window.history.pushState({}, selectedJob.title, `/vacancies/${id}/`)
  }
  const onClose = useCallback(() => {
    setIsOpen(false)
    window.history.pushState({}, title, '/')
  }, [])

  useEffect(() => {
    if (isOpen && selectedJob) {
      onOpen()
    }
  }, [])
  return (
    <Section id='jobs'>
      <JobsContainer>
        <Centered>
          <Title>Vacancies</Title>
          <SeparatorBlock />
          <JobsList>
            <>
              {vacancies.map((job, index) => (
                <JobsListInner key={index}>
                  <JobItem
                    onClick={() => {
                      setIsOpen(true)
                      setSelectedJob(job)
                    }}
                  >
                    <JobTitle>{job.title}</JobTitle>
                    <JobLocation>{job.location}</JobLocation>
                  </JobItem>
                </JobsListInner>
              ))}
            </>
            <Modal
              isOpen={isOpen}
              onRequestClose={onClose}
              onAfterOpen={onOpen}
              style={modalStyles}
              contentLabel='job-modal'
            >
              {selectedJob && (
                <>
                  <JobModalHeader>
                    <ModalHeaderBlock>{selectedJob.title}</ModalHeaderBlock>
                    <ModalHeaderJobLocation>
                      {selectedJob.location}
                    </ModalHeaderJobLocation>
                  </JobModalHeader>
                  <JobModalInner>
                    <JobsText {...RichTextToHtml(selectedJob.text)} />
                    <JobsForm job={selectedJob} webhook={webhook} />
                  </JobModalInner>
                </>
              )}
              <BackToJobs onClick={() => setIsOpen(false)}>
                Back to jobs...
              </BackToJobs>
            </Modal>
          </JobsList>
        </Centered>
      </JobsContainer>
    </Section>
  )
}
