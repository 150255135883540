import { withPrefix, navigate } from 'gatsby'

export const isExternal = (path) => {
  return (
    /^http[s]?:\/\//g.test(path) ||
    path.startsWith('mailto:') ||
    path.startsWith('tel:')
  )
}

export const getRedirectUrl = (path) => {
  return isExternal(path) ? path : withPrefix(path)
}

export const isBrowser = () => {
  return typeof window !== 'undefined'
}

export const navigateTo = (url, options) => {
  if (isExternal(url) && isBrowser()) {
    window.location.href = url
  } else if (isBrowser()) {
    navigate(url, options)
  }
}

export const Scroll = (offsetTop = 0) => {
  if (isBrowser()) {
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    })
  }
}

export const RichTextToHtml = (text, validation = (html) => html) => ({
  dangerouslySetInnerHTML: {
    __html: validation(text.childContentfulRichText.html),
  },
})
