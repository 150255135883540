import React from 'react'
import {
  FooterContainer,
  Copyright,
  FooterSocials,
  LinkLogo,
  LinkStyled,
} from './styles'
import { Centered } from '../../../../styles'
import { map } from 'lodash-es'
import { Img } from '../../../common'
import { RichTextToHtml } from '../../../../helpers'

export const Footer = ({ logo, links, text }) => (
  <FooterContainer>
    <Centered>
      <LinkLogo to='#home-page'>
        <Img src={logo} alt='poz-logo' />
      </LinkLogo>
      <Copyright
        {...RichTextToHtml(text, (html) =>
          html.replace('%time%', new Date().getFullYear().toString())
        )}
      />
      <FooterSocials>
        {map(links, ({ url, behaviour, image }, key) => (
          <LinkStyled to={url} behaviour={behaviour} key={key}>
            <Img src={image} />
          </LinkStyled>
        ))}
      </FooterSocials>
    </Centered>
  </FooterContainer>
)
