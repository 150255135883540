import React, { useState } from 'react'
import { PortfolioContainer, Title, ButtonsWrapper, Button } from './styles'
import { Centered, Section } from '../../../styles'
import { ProjectsTab, TeamTab } from './components'
import { SeparatorBlock } from '../../common'

export const Portfolio = ({ title, photos, projects, sliderArrow }) => {
  const [openTab, setOpenTab] = useState('Our Photos')
  const tabs = ['Our Photos', 'Our Projects']
  return (
    <Section id='work'>
      <PortfolioContainer>
        <Centered>
          <Title>{title}</Title>
          <SeparatorBlock />
          <ButtonsWrapper>
            {tabs.map((item, index) => (
              <Button
                active={item === openTab}
                key={index}
                onClick={() => setOpenTab(item)}
              >
                {item}
              </Button>
            ))}
          </ButtonsWrapper>
          {openTab === 'Our Photos' ? (
            <TeamTab photos={photos} />
          ) : (
            <ProjectsTab projects={projects} sliderArrow={sliderArrow} />
          )}
        </Centered>
      </PortfolioContainer>
    </Section>
  )
}
