import styled from 'styled-components'

export const JobsContainer = styled.div`
  padding: 2% 0;
`

export const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: 46px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 767.98px) {
    font-size: 36px;
  }
`

export const JobsList = styled.div`
  position: relative;
  margin: 30px 0;
`

export const JobsListInner = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`

export const JobItem = styled.div`
  position: relative;
  cursor: pointer;
`

export const JobTitle = styled.h3`
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
  font-weight: 700;
`

export const JobLocation = styled.p`
  color: #9bcd36;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 100;
`

export const JobModalHeader = styled.div`
  color: #eee;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 18px;
  max-height: 200px;
  width: 100%;
  background-color: #ff7a00;
  padding: 15px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const ModalHeaderBlock = styled.div`
  display: flex;
  padding: 0 10px;
  font-size: 18px;

  @media (max-width: 600px) {
    margin: 0 auto;
    text-align: center;
    margin-top: 5px;
  }
`
export const ModalHeaderJobLocation = styled(ModalHeaderBlock)`
  font-weight: bold;
`

export const JobModalInner = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
  padding: 0 25px 25px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  h3 {
    font-size: 26px;
    margin: 60px 0 30px 0;
  }
`

export const JobsText = styled.div`
  width: 60%;
  @media (max-width: 900px) {
    width: 100%;
  }
`
export const BackToJobs = styled.div`
  background: #fff;
  color: #95c238;
  cursor: pointer;
  font-size: 18px;
  padding: 30px 0;
  text-align: center;
  position: sticky;
  bottom: 0;
`

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .3)',
    zIndex: 9999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    background: '#eff0f0',
    maxHeight: '100vh',
    marginRight: '-50%',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    maxWidth: '1170px',
    width: '100%',
  },
}
