import styled from 'styled-components'
import { Img, Link } from '../../common'

export const AboutContainer = styled.div`
  padding: 4% 0;
`

export const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: 46px;
  font-weight: 800;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  @media (max-width: 767.98px) {
    font-size: 36px;
  }
`

export const AboutContent = styled.div`
  color: #fff;
  text-align: center;
  margin-top: 30px;
`

export const AboutSocialLogo = styled.div`
  text-align: center;
  margin-top: 30px;
`

export const AboutLink = styled(Link)`
  margin: 20px 15px;
  @media (max-width: 575.98px) {
    margin: 20px 5px;
  }
`
export const Background = styled(Img)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const LinkImage = styled(Img)`
  display: inline-block;
  filter: grayscale(100%);
  max-width: 75px;
  width: 100%;
  transition: filter 0.1s ease-in-out;
  &:hover {
    transition: filter 0.1s ease-in-out;
    filter: grayscale(0%);
  }
  @media (max-width: 767.98px) {
    max-width: 60px;
    width: 100%;
  }
`
