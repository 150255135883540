import React from 'react'
import { Layout } from '../components/common'
import {
  About,
  Contact,
  Hero,
  Map,
  Portfolio,
  Skills,
  Statistics,
  Team,
  Testimonials,
  Vacancies,
} from '../components/homePage'
import { graphql } from 'gatsby'

const IndexPage = ({ data, '*': url }) => {
  const {
    heroSection,
    skillsSection,
    aboutUsSection,
    portfolioSection,
    testimonialsSection,
    teamSection,
    vacanciesSection,
    statisticSection,
    contactSection,
    mapSection,
    layout,
    bigLogo,
    smallLogo,
    scroll,
    swipe,
    sliderArrow,
    heroArrow,
    keywords,
    discordWebhook,
  } = data.contentfulPozitiffSite
  const { title } = data.site.siteMetadata
  const vacancies = url.match(/vacancies\/(\d+)/)
  return (
    <Layout {...{ ...layout, bigLogo, smallLogo, keywords }}>
      <Hero {...{ ...heroSection, scroll, swipe, heroArrow }} />
      <Skills {...skillsSection} />
      <About {...aboutUsSection} />
      <Portfolio {...{ ...portfolioSection, sliderArrow }} />
      <Testimonials
        {...{ ...testimonialsSection, sliderArrow, scroll, swipe }}
      />
      <Team {...teamSection} />
      <Statistics {...statisticSection} />
      <Vacancies
        {...vacanciesSection}
        jobId={vacancies ? vacancies[1] : null}
        title={title}
        webhook={discordWebhook}
      />
      <Contact {...contactSection} webhook={discordWebhook} />
      <Map {...mapSection} />
    </Layout>
  )
}

export const query = graphql`
  query {
    ...PozSite
  }
`

export default IndexPage
