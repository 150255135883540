import styled, { css } from 'styled-components'
import { Link } from '../../../common/link/Link' // Gatsvy strange bug
import { Img } from '../../../common/img/Img'

export const Header = styled.header`
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
  @media (max-width: 900px) {
    max-width: 100%;
    right: 0;
  }
`
export const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`

export const Nav = styled.nav`
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled(Link).attrs((p) => {
  return p.theme.localLink
})`
  cursor: pointer;
  display: inline-block;
  width: 100%;
  max-width: 350px;
  @media (max-width: 900px) {
    max-width: 250px;
  }
`

export const Menu = styled.div`
  display: flex;
  @media (max-width: 900px) {
    display: none;
  }
  ${(p) =>
    p.isOpen &&
    css`
      display: block !important;
      position: absolute;
      right: 0;
      left: 0;
      top: 70px;
      z-index: 999;
      background: rgba(255, 255, 255, 0.95);
    `}
`

export const Burger = styled.div`
  width: 30px;
  height: 22px;
  display: none;
  transform: rotate(0);
  transition: 0.3s ease-in-out;
  cursor: pointer;

  @media (max-width: 900px) {
    display: flex;
    max-width: 100%;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #434343;
    opacity: 1;
    left: 0;
    transform: rotate(0);
    transition: all 0.3s ease-in-out;
    @media (max-width: 900px) {
      display: flex;
      max-width: 100%;
    }
  }
  span:nth-child(1) {
    top: 0;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }
  span:nth-child(4) {
    top: 20px;
  }

  ${(p) =>
    p.isOpen &&
    css`
      span:nth-child(1),
      span:nth-child(4) {
        top: 10px;
        width: 0;
        left: 50%;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
        background: #c4c4c4;
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
        background: #c4c4c4;
      }
    `}
`

export const LinkStyled = styled(Link).attrs((p) => ({
  ...p.theme.localLink,
}))`
  display: inline-block;
  font: 15px/42px 'Exo 2', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #434343;
  cursor: pointer;
  transition: color 0.2s ease;
  position: relative;

  &:not(:last-child) {
    margin-right: 25px;
  }

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -10px;
    left: 0;
    background-color: #7abd33;
    visibility: hidden;
    transition: all 0.2s ease-in-out 0s;
    transform: scaleX(0);
    @media (max-width: 767.98px) {
      bottom: -4px;
    }
  }

  &.active {
    color: #7abd33;
  }

  &:hover {
    color: #7abd33;
    ::before {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  @media (max-width: 767.98px) {
    display: block;
    padding: 15px;
    border-top: 1px solid #c4c4c4;
    text-align: center;

    &:not(:last-child) {
      margin-right: 0;
    }

    &:last-child {
      border-bottom: 1px solid $background_grey;
    }
  }
`

export const LogoImg = styled(Img)`
  height: 50px;
  width: 250px;
`
