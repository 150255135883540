import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import { MapContainer } from './styles'
import { useDelayRender } from '../../hooks'

const MapComponent = withScriptjs(
  withGoogleMap(({ prahaOffice, lvivOffice, zoom, marker, center }) => (
    <GoogleMap defaultZoom={zoom} defaultCenter={center}>
      <Marker position={prahaOffice} icon={marker.file.url} />
      <Marker position={lvivOffice} icon={marker.file.url} />
    </GoogleMap>
  ))
)

export const Map = ({ apiUrl, ...props }) => {
  const ready = useDelayRender(3000)
  return (
    <MapContainer>
      {ready && (
        <MapComponent
          googleMapURL={apiUrl}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '400px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          {...props}
        />
      )}
    </MapContainer>
  )
}
