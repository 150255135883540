import React, { useCallback, useRef } from 'react'
import { VacanciesForm, Input, MessageInput, Submit } from './styles'
import { useSetState } from '../../../hooks'
import axios from 'axios'

export const JobsForm = ({ job, webhook }) => {
  const files = useRef()
  const reset = useCallback(() => ({
    name: '',
    email: '',
    phone: '',
    linkedIn: '',
    file: '',
    message: '',
  }))
  const [{ name, email, phone, linkedIn, file, message }, set] = useSetState(
    reset
  )

  const onChange = useCallback(({ target: { value, name } }) => {
    set({ [name]: value })
  })

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()

    data.set(
      'payload_json',
      JSON.stringify({
        username: name,
        embeds: [
          {
            fields: [
              {
                name: "**Sender's name:** ",
                value: name,
                inline: false,
              },
              {
                name: "**Sender's email:** ",
                value: email,
                inline: false,
              },
              {
                name: "**Sender's phone:** ",
                value: phone,
                inline: false,
              },
              {
                name: "**Sender's linkedIn:** ",
                value: linkedIn,
                inline: false,
              },
              {
                name: "**Sender's Message:** ",
                value: message,
                inline: false,
              },
            ],
            title: 'New Message Received :incoming_envelope:',
            description: `Job vacancies : **${job.title} ($${job.salary})**`,
          },
        ],
      })
    )
    data.append('CV', files.current.files[0])
    try {
      const response = await axios.post(webhook, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      console.log(response)
    } catch (error) {
      console.log(error)
    }
    set(reset)
  }

  return (
    <VacanciesForm onSubmit={onSubmit}>
      <Input
        type='text'
        name='name'
        placeholder='Your Name'
        required
        value={name}
        onChange={onChange}
      />
      <Input
        type='email'
        name='email'
        placeholder='Your Email'
        required
        value={email}
        onChange={onChange}
      />
      <Input
        type='tel'
        name='phone'
        placeholder='Your Phone Number'
        required
        value={phone}
        onChange={onChange}
      />
      <Input
        type='url'
        name='linkedIn'
        placeholder='Your Linkedin URL'
        required
        value={linkedIn}
        onChange={onChange}
      />
      <Input
        type='file'
        name='file'
        placeholder='Attach Your CV'
        accept='application/msword, application/pdf'
        required
        value={file}
        onChange={onChange}
        ref={files}
      />
      <MessageInput
        name='message'
        placeholder='Message'
        rows='8'
        required
        value={message}
        onChange={onChange}
      />
      <Submit type='submit' value='Submit' />
    </VacanciesForm>
  )
}
