import React from 'react'
import T from 'prop-types'
import { Navigation, Footer } from '../../homePage/common'
import { Seo } from '..'
import { Global } from '../../../styles'
import { ThemeProvider } from 'styled-components'
import { main } from '../../theme'
import Modal from 'react-modal'

Modal.setAppElement('body')

export const Layout = ({
  children,
  bigLogo,
  smallLogo,
  navBarLinks,
  footerLinks,
  footerText,
  keywords,
}) => (
  <ThemeProvider theme={main}>
    <>
      <Global />
      <Seo title='PozitiFF Information Technologies' keywords={keywords} />
      <Navigation logo={bigLogo} links={navBarLinks} />
      <main>{children}</main>
      <Footer logo={smallLogo} links={footerLinks} text={footerText} />
    </>
  </ThemeProvider>
)

Layout.propTypes = {
  children: T.node.isRequired,
}
