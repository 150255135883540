import React from 'react'
import { Zoom } from 'react-reveal'
import {
  Row,
  Item,
  ItemWrapper,
  TeamContainer,
  Mask
} from './styles'

export const TeamTab = ({ photos }) => {


  return (
    <TeamContainer>
      {printDiamond(photos).map((row, i) => (
        <Row key={i}>
          {row.map((photo, x) => (
            <Zoom key={x} duration={500} delay={100 * (x+1) }>
              <Mask >
                <ItemWrapper >
                  <Item src={photo}/>
                </ItemWrapper>
              </Mask>
            </Zoom>
          ))}
        </Row>
      ))}
    </TeamContainer>
  )
}

function printDiamond(mas) {
  let n = Math.sqrt(mas.length)
  let index = 0
  const result = []
  for (let i = 0; i < n; i++) {
    let arr = []
    for (let j = 0; j <= i; j++, index++) {
      arr.push(mas[index])
    }
    result.push(arr)
  }
  for (let i = n - 1; i > 0; i--) {
    let arr = []
    for (let j = 0; j < i; j++, index++) {
      arr.push(mas[index])
    }
    result.push(arr)
  }
  return result
}