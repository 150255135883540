import styled, { css, keyframes } from 'styled-components'
import { Img } from '../../common'

export const TestimonialsContainer = styled.div`
  min-height: 700px;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-around;
  position: relative;
  width: 100%;
  overflow: hidden;
`

export const BackgroundImg = styled(Img)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const Title = styled.h2`
  z-index: 1;
  font-family: 'Exo 2', sans-serif;
  font-size: 46px;
  font-weight: 800;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  @media (max-width: 767.98px) {
    font-size: 36px;
  }
`

export const ContentWrapper = styled.div`
  position: relative;
  width: 80%;
  padding: 40px 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
`

export const AuthorRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 10px;
`

export const AuthorImage = styled(Img)`
  max-width: 100px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 0 auto;
  display: inline-block;
  &.second {
    margin-left: 30px;
  }
`
export const AuthorName = styled.h3`
  font-size: 24px;
  margin-top: 10px;
  color: #9bcd36;
`

export const TestimonialTextWrapper = styled.div`
  margin-top: 50px;
`

export const TestimonialText = styled.p`
  size: 18px;
  color: white;
`

export const Container = styled.div`
  width: 100%;
  flex: 1 1;
  display: flex;
  text-align: center;
  position: relative;

  @media (max-width: 900px) {
    text-align: center;
  }
`

export const Arrow = styled(Img)`
  z-index: 100;
  position: absolute;
  top: 0;
  height: 45px;
  width: 45px;
  filter: invert(50%);

  &:hover {
    filter: invert(50%) opacity(0.1) drop-shadow(0 0 0 #9bcd36);
  }

  ${(p) =>
    p.right
      ? css`
          right: 5%;
          top: 40%;
        `
      : css`
          transform: rotate(180deg);
          left: 5%;
          top: 40%;
        `}
`

export const Dots = styled.ul`
  text-align: center;
  width: 300px;
  z-index: 100;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
`

export const Dot = styled.li`
  list-style-type: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin: 0 10px;
  cursor: pointer;

  transition: all 0.5s ease-in-out;
  position: relative;
  ${(p) =>
    p.active &&
    css`
      background: #9bcd36;
      border-color: #9bcd36;
      animation: ${dotActive} 0.5s ease-in-out forwards;
    `}
`

const dotActive = keyframes`

  0%
  {
    box-shadow:0 0 0 0 #eee
    }
  35%{box-shadow:0 0 10px 5px #eee}
  70%{box-shadow:0 0 10px 5px #9bcd36}
  100%{box-shadow:0 0 0 0 #9bcd36}
`
