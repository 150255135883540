import styled from 'styled-components'

export const Divider = styled.span`
  display: inline-block;
  transform: rotate(45deg);
  font-size: 18px;
  margin: 0 4px;
`

export const Separator = styled.div`
  max-height: 47px;
  height: 100%;
  max-width: 100px;
  width: 100%;
  margin: 0 auto;
  color: ${(p) => p.color};
`
