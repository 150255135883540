import React, { useEffect, useState, useRef } from 'react'
import {
  Dot,
  Dots,
  Arrow,
  AuthorRow,
  AuthorWrapper,
  AuthorImage,
  AuthorName,
  ContentWrapper,
  BackgroundImg,
  TestimonialsContainer,
  Container,
  Title,
  TestimonialTextWrapper,
  TestimonialText,
} from './styles'
import makeCarousel from 'react-reveal/makeCarousel'
import { Slide, Fade, Zoom } from 'react-reveal'
import { Section } from '../../../styles'
import { ScrollImage } from '../../common'

export const Testimonials = ({
  background,
  testimonials,
  sliderArrow,
  scroll,
  swipe,
}) => {
  const [initial, setInitial] = useState(false)
  const section = useRef(null)
  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined') {
      new IntersectionObserver((entries) => {
        if (entries.find((x) => x.isIntersecting)) {
          setInitial(true)
        }
      }).observe(section.current)
    } else {
      setInitial(true)
    }
  }, [])
  const CarouselUI = ({ position, handleClick, children, total }) => (
    <Container>
      {children}

      <Arrow
        onClick={handleClick}
        data-position={position - 1}
        src={sliderArrow}
      />
      <Arrow
        right
        onClick={handleClick}
        data-position={position + 1}
        src={sliderArrow}
      />
      <Dots>
        {Array(...Array(total)).map((val, index) => (
          <Dot
            key={index}
            onClick={handleClick}
            data-position={index}
            active={index === position}
          />
        ))}
      </Dots>
    </Container>
  )
  const Carousel = makeCarousel(CarouselUI)

  return (
    <Section id='testimonials' ref={section}>
      <TestimonialsContainer>
        <BackgroundImg src={background} alt='background-img' />
        <Title>TESTIMONIALS</Title>
        <Carousel
          defaultWait={initial ? 5000 : 9999999999999}
          maxTurns={testimonials.length * 2}
        >
          {testimonials.map((item, i) => (
            <Slide key={i} right>
              <ContentWrapper>
                <AuthorRow>
                  {item.authorPhoto.map((author, y) => (
                    <Zoom clear opposite key={y}>
                      <AuthorWrapper>
                        <AuthorImage src={author} />
                        <AuthorName>{author.title}</AuthorName>
                      </AuthorWrapper>
                    </Zoom>
                  ))}
                </AuthorRow>

                <Fade bottom opposite>
                  <TestimonialTextWrapper>
                    <TestimonialText>{item.text}</TestimonialText>
                  </TestimonialTextWrapper>
                </Fade>
              </ContentWrapper>
            </Slide>
          ))}
        </Carousel>
      </TestimonialsContainer>

      <ScrollImage to='#team' swipe={swipe} scroll={scroll} />
    </Section>
  )
}
