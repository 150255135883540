import React from 'react'
import {
  Header,
  Container,
  Nav,
  Logo,
  Burger,
  Menu,
  LinkStyled,
  LogoImg,
} from './styles'
import { useToggle } from '../../../hooks'
import { map } from 'lodash-es'

export const Navigation = ({ logo, links }) => {
  const [isOpen, toggle, setIsOpen] = useToggle(false)
  return (
    <Header>
      <Container>
        <Nav>
          <Logo to='#home-page'>
            <LogoImg src={logo} alt='brand-logo' />
          </Logo>

          <Burger onClick={toggle} isOpen={isOpen}>
            <span />
            <span />
            <span />
            <span />
          </Burger>
          <Menu isOpen={isOpen}>
            {map(links, ({ url, title }, index) => (
              <LinkStyled
                key={index}
                to={url}
                onClick={() => {
                  if (isOpen) {
                    setIsOpen(false)
                  }
                }}
              >
                {title}
              </LinkStyled>
            ))}
          </Menu>
        </Nav>
      </Container>
    </Header>
  )
}
