import styled from 'styled-components'

export const PortfolioContainer = styled.div`
  padding: 2% 0;
  background-color: #eee;
`

export const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: 46px;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  @media (max-width: 767.98px) {
    font-size: 36px;
  }
`
export const ButtonsWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
`

export const Button = styled.div`
  border: 1px solid #000000;
  border-radius: 0;
  font-family: 'Exo 2', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.42857;
  padding: 8px 20px;
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ active }) => (active ? '#ffffff' : '#000000')};
  background: ${({ active }) => (active ? '#000000' : '#ffffff')};

  &:hover {
    background-color: #000000;
    border: 1px solid #000000;
    color: #ffffff;
    transition-duration: 0.2s;
  }

  @media (max-width: 991.98px) {
    font-size: 16px;
    padding: 8px 14px;
  }
`
