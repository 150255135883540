import React from 'react'
import { Fade } from 'react-reveal'
import {
  TeamContainer,
  Title,
  TeamRow,
  TeamItem,
  MemberBlock,
  DiamondWrapper,
  DiamondBox,
  DiamondBoxInner,
  MemberPosition,
  MemberName,
} from './styles'
import { Section } from '../../../styles'
import { SeparatorBlock, Img, Link } from '../../common'
import { chunk } from 'lodash-es'

export const Team = ({ rows, title }) => (
  <Section id='team'>
    <TeamContainer>
      <Title>{title}</Title>
      <SeparatorBlock />
      {rows.map(({ memberItem }, rIndex) => (
        <React.Fragment key={rIndex}>
          {chunk(memberItem, 4).map((chunkRow, cIndex) => (
            <Fade bottom key={cIndex}>
              <TeamRow>
                {chunkRow.map(
                  ({ photo, position, name, linkedInUrl }, mIndex) => (
                    <TeamItem key={mIndex}>
                      <MemberBlock>
                        <DiamondWrapper>
                          <DiamondBox>
                            <DiamondBoxInner>
                              <Link
                                to={linkedInUrl || '#'}
                                behaviour='new tab'
                                stop
                              >
                                <Img src={photo} />
                              </Link>
                            </DiamondBoxInner>
                          </DiamondBox>
                        </DiamondWrapper>
                        <SeparatorBlock />
                        <MemberPosition>{position}</MemberPosition>
                      </MemberBlock>
                      <MemberName>{name}</MemberName>
                    </TeamItem>
                  )
                )}
              </TeamRow>
            </Fade>
          ))}
        </React.Fragment>
      ))}
    </TeamContainer>
  </Section>
)
