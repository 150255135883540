import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  StatisticsContainer,
  Stuff,
  Title,
  BackgroundImage,
  BlocksContainer,
  Block,
  CounterTitle,
  Subtitle,
  StatisticImage,
} from './style'
import { Centered, Section } from '../../../styles'
import { SeparatorBlock } from '../../common'

export const Statistics = ({
  api,
  projects,
  background,
  projectsImage,
  timeImage,
  coffeeImage,
}) => {
  const [hours, setHours] = useState(42122.77)

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { hours },
        } = await axios.get(api)
        setHours(hours)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  const cupsOfCoffee = ((parseFloat(hours).toFixed(2) / 24) * 6).toFixed()
  return (
    <Section id='statistics'>
      <StatisticsContainer>
        <BackgroundImage src={background} a />
        <Stuff>
          <Centered padding='5em 0'>
            <Title>some cool statistics</Title>
            <SeparatorBlock color='white' />
            <BlocksContainer>
              <Block>
                <StatisticImage src={projectsImage} />
                <CounterTitle>{projects}</CounterTitle>
                <Subtitle>
                  <strong>projects</strong>
                  <br />
                  COMPLETED
                </Subtitle>
              </Block>
              <Block>
                <StatisticImage src={timeImage} />
                <CounterTitle>{hours}</CounterTitle>
                <Subtitle>
                  <strong>HOURS</strong>
                  <br />
                  WORKED
                </Subtitle>
              </Block>
              <Block>
                <StatisticImage src={coffeeImage} />
                <CounterTitle>{cupsOfCoffee}</CounterTitle>
                <Subtitle>
                  <strong>CUPS</strong>
                  <br />
                  OF COFFEE
                </Subtitle>
              </Block>
            </BlocksContainer>
          </Centered>
        </Stuff>
      </StatisticsContainer>
    </Section>
  )
}
