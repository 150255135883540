import styled, { css } from 'styled-components'
import { Img } from '../../../../../common'

export const ProjectSliderContainer = styled.div`
  max-width: 1140px;
  min-height: 350px;
  overflow: hidden;
  @media (max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`

export const Container = styled.div`
  display: flex;
  position: relative;
  min-height: 350px;
  overflow: hidden;
  @media (max-width: 900px) {
    width: 100%;
    min-height: 620px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  margin: 40px auto 0;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  flex-direction: ${(p) => (p.position === 'left' ? 'row' : 'row-reverse')};
  @media (max-width: 900px) {
    flex-direction: column;
    align-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
  }
`

export const Image = styled(Img)`
  max-height: 265px;
  max-width: 445px;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
`

export const SlideImg = styled.div`
  width: 44%;
  padding: 0 15px;
  @media (max-width: 900px) {
    min-height: 300px;
    min-width: 300px;
    width: 100%;
  }
`

export const SlideDescription = styled.div`
  width: 44%;
  padding: 0 15px;
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const Arrow = styled(Img)`
  z-index: 100;
  height: 45px;
  width: 45px;
  filter: invert(50%);
  position: absolute;
  top: 50%;
  right: 0;

  @media (max-width: 900px) {
    display: block;
  }

  &:hover {
    filter: unset;
    transition: filter 0.15s ease-in-out;
  }

  ${({ right }) =>
    right
      ? css`
          right: 0%;
        `
      : css`
          transform: rotate(180deg);
          left: 0%;
        `}
`

export const SlideTitle = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 30px;
`

export const SlideP = styled.div`
  margin-bottom: 10px;
`
