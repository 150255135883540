import React from 'react'
import { RichTextToHtml } from '../../../helpers'
import {
  AboutContainer,
  Title,
  AboutContent,
  AboutSocialLogo,
  AboutLink,
  LinkImage,
  Background,
} from './styles'
import { Centered, Section } from '../../../styles'
import { SeparatorBlock } from '../../common'

export const About = ({ title, description, background, links }) => (
  <Section id='about'>
    <AboutContainer>
      <Background src={background} />
      <Centered>
        <Title>{title}</Title>
        <SeparatorBlock color='white' />
        <AboutContent {...RichTextToHtml(description)} />
        <AboutSocialLogo>
          {links.map(({ url, image, behaviour }, index) => (
            <AboutLink key={index} behaviour={behaviour} to={url}>
              <LinkImage src={image} />
            </AboutLink>
          ))}
        </AboutSocialLogo>
      </Centered>
    </AboutContainer>
  </Section>
)
