import React from 'react'
import makeCarousel from 'react-reveal/makeCarousel'
import { Slide } from 'react-reveal'
import { RichTextToHtml } from '../../../helpers'
import { SeparatorBlock, ScrollImage } from '../../common'
import {
  Arrow,
  Container,
  SlideInner,
  SlideDiamondBg,
  SlideContent,
  Title,
  Description,
  BackgroundImage,
  ArrowImage,
  Center,
} from './styles'
import { Section } from '../../../styles'

export const Hero = ({ heroItems, scroll, swipe, heroArrow }) => {
  const Carousel = makeCarousel(({ position, handleClick, children }) => (
    <Container>
      {children}
      <Arrow onClick={handleClick} data-position={position - 1}>
        <ArrowImage src={heroArrow} />
      </Arrow>
      <Arrow right onClick={handleClick} data-position={position + 1}>
        <ArrowImage src={heroArrow} />
      </Arrow>
    </Container>
  ))

  return (
    <Section id='home-page'>
      <Carousel defaultWait={4000} maxTurns={4}>
        {heroItems.map((heroItem, index) => (
          <Slide right key={index}>
            <>
              <BackgroundImage
                src={heroItem.backgroundImage}
                alt='hero-slide'
              />
              <SlideInner>
                <Center>
                  <SlideDiamondBg>
                    <SlideContent>
                      <Title {...RichTextToHtml(heroItem.title)} />
                      <SeparatorBlock color='white' />
                      <Description {...RichTextToHtml(heroItem.description)} />
                    </SlideContent>
                  </SlideDiamondBg>
                </Center>
              </SlideInner>
            </>
          </Slide>
        ))}
      </Carousel>

      <ScrollImage to='#skills' scroll={scroll} swipe={swipe}/>
    </Section>
  )
}
