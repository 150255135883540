import styled from 'styled-components'
import { Img, Link } from '../index'

export const ScrollTop = styled.div`
  position: absolute;
  text-align: center;
  bottom: -57px;
  z-index: 2;
  width: 100%;
  @media (max-width: 600px) {
    bottom: -45px;
  }
`

export const StyledLink = styled(Link).attrs((p) => p.theme.localLink)`
  cursor: pointer;
  display: inline-block;
  max-width: 104px;
  width: 100%;
  @media (max-width: 600px) {
    max-width: 80px;
  }
`

export const ScrollImg = styled(Img)`
  display: block;
  height: 104px;
  @media (max-width: 600px) {
    display: none;
    height: 80px;
  }
`

export const SwipeImg = styled(Img)`
  display: none;
  height: 104px;
  @media (max-width: 600px) {
    display: block;
    height: 80px;
  }
`
