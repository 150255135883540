import React, { useCallback } from 'react'
import axios from 'axios'
import {
  FormStyle,
  Form,
  NameInput,
  EmailInput,
  WebsiteInput,
  SubjectInput,
  MessageInput,
  SubmitButton,
} from './styles'
import { useSetState } from '../../../hooks'

export const ContactForm = ({ rhomb, webhook }) => {
  const reset = useCallback(
    () => ({
      username: '',
      email: '',
      website: '',
      subject: '',
      content: '',
    }),
    []
  )

  const [
    { username, email, website, subject, content },
    setState,
  ] = useSetState(reset)

  const handleChange = useCallback(({ target: { value, name } }) => {
    setState({ [name]: value })
  })

  const handleSubmit = async (event) => {
    event.preventDefault()

    const discordData = {
      username,
      embeds: [
        {
          fields: [
            {
              name: "**Sender's name:** ",
              value: username,
              inline: false,
            },
            {
              name: "**Sender's email:** ",
              value: email,
              inline: false,
            },
            {
              name: "**Sender's webpage:** ",
              value: website,
              inline: false,
            },
            {
              name: '**Message subject:** ',
              value: subject,
              inline: false,
            },
          ],
          title: 'New Message Received :incoming_envelope:',
        },
      ],
      content,
    }

    try {
      const response = await axios.post(
        webhook,
        JSON.stringify(discordData, null, 2)
      )
      console.log(response)
    } catch (error) {
      console.log(error)
    }
    setState(reset)
  }

  return (
    <FormStyle>
      <Form onSubmit={handleSubmit}>
        <NameInput
          name='username'
          type='text'
          placeholder='Name'
          onChange={handleChange}
          required
          value={username}
        />
        <EmailInput
          name='email'
          placeholder='Email'
          type='email'
          onChange={handleChange}
          required
          value={email}
        />
        <WebsiteInput
          name='website'
          type='text'
          placeholder='Website'
          onChange={handleChange}
          value={website}
        />
        <SubjectInput
          name='subject'
          type='text'
          placeholder='Subject'
          onChange={handleChange}
          required
          value={subject}
        />
        <MessageInput
          name='content'
          placeholder='Your Message'
          onChange={handleChange}
          required
        />
        <SubmitButton type='submit' rhomb={rhomb}>
          Send
        </SubmitButton>
      </Form>
    </FormStyle>
  )
}
