import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import T from 'prop-types'
import { isExternal } from '../../../helpers'
import { Link as SpyLink } from 'react-scroll'

export const Link = ({
  to,
  children,
  activeClassName,
  partiallyActive,
  behaviour,
  spy,
  smooth,
  activeClass,
  stop,
  ...props
}) => {
  if (to.startsWith('#')) {
    if (to.length === 1) {
      return (
        <a
          href='#'
          {...props}
          {...(stop
            ? {
                onClick: (e) => {
                  e.preventDefault()
                },
              }
            : {})}
        >
          {children}
        </a>
      )
    }
    return (
      <SpyLink
        to={to.replace('#', '')}
        spy={spy}
        smooth={smooth}
        activeClass={activeClass}
        {...props}
      >
        {children}
      </SpyLink>
    )
  } else if (isExternal(to)) {
    return (
      <a
        href={to}
        {...props}
        {...(behaviour === 'new tab'
          ? { rel: 'noopener noreferrer', target: '_blank' }
          : {})}
      >
        {children}
      </a>
    )
  } else {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...props}
      >
        {children}
      </GatsbyLink>
    )
  }
}
Link.propTypes = {
  to: T.string.isRequired,
  activeClassName: T.bool,
  partiallyActive: T.bool,
  behaviour: T.oneOf(['new tab', 'current tab']),
}
