import styled, { css } from 'styled-components'
import { Link } from 'react-scroll'
import { Img } from '../../common'
import { Centered } from '../../../styles'

export const Container = styled.div`
  position: relative;
  height: 105vh;
  display: flex;
  margin-top: 70px;
  overflow: hidden;
  @media (max-width: 900px) {
    height: 100vh;
  }

  .react-reveal {
    height: 100%;
  }
`

export const Center = styled(Centered)`
  height: 100vh;
  display: flex;
  align-items: center;
`

export const Arrow = styled.div`
  z-index: 100;
  top: 47.6%;
  position: absolute;
  transform: translateY(-50%);

  user-select: none;
  ${(props) =>
    props.right
      ? css`
          right: 0;
          transform: translateY(-50%) rotate(180deg);
        `
      : css`
          left: 0%;
        `}

  @media (max-width: 900px) {
    ${(props) =>
      props.right
        ? css`
            right: 0;
            transform: translateY(250%) rotate(180deg);
          `
        : css`
            left: 0%;
            transform: translateY(250%);
          `}
  }
`

export const ArrowImage = styled(Img)`
  position: relative;
  width: 85px;
  height: 85px;
  cursor: pointer;
`

export const SlideInner = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const SlideDiamondBg = styled.div`
  border: 1px solid white;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  max-width: 550px;
  max-height: 550px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transform: translate(-50%, -50%) rotate(45deg);
  @media (max-width: 960px) {
    border: none;
    transform: translate(-50%, -50%) rotate(0);
  }
`

export const SlideContent = styled.div`
  transform: rotate(-45deg);
  color: #fff;
  margin: 0 auto;
  max-width: 500px;
  position: relative;
  width: 100%;
  text-align: center;
  height: auto !important;
  @media (max-width: 960px) {
    transform: none;
  }
`

export const Title = styled.div`
  p,
  h1,
  u {
    font-size: 48px;
    font-weight: 800;
    font-family: 'Exo2', sans-serif;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 1.1;
    text-decoration: none;
  }
  u {
    color: #9ecf31;
  }
`

export const Description = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 100;
  margin: 0;
`

export const BackgroundImage = styled(Img)`
  height: 100%;
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const ScrollTop = styled.div`
  position: absolute;
  text-align: center;
  bottom: -60px;
  z-index: 2;
  width: 100%;
`

export const StyledLink = styled(Link)`
  cursor: pointer;
  display: inline-block;
  max-width: 105px;
  width: 100%;
`
