import styled from 'styled-components'

export const VacanciesForm = styled.form`
  background-color: #95c238;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 60px;
  margin-top: 60px;
  height: fit-content;
  width: 40%;
  @media (max-width: 900px) {
    width: 100%;
  }
`

export const Input = styled.input`
  background-color: #fff;
  background-image: none !important;
  border: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 15px 10px;
  ::placeholder {
    color: #c4c4c4;
  }
`

export const MessageInput = styled.textarea`
  border: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 15px 10px;
  resize: vertical;
  ::placeholder {
    color: #c4c4c4;
  }
`

export const Submit = styled.input`
  background-image: none !important;
  background-color: #ff7a00;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  padding: 20px 5px;
  text-transform: uppercase;
`
