import styled from 'styled-components'

export const FormStyle = styled.div`
  bottom: 0;
  display: block;
  left: -15%;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: -15%;
  transform: rotate(-45deg);
  width: 100%;
  @media (max-width: 767.98px) {
    transform: rotate(0deg);
    min-height: 765px;
    margin: 12% auto 0;
    overflow: visible;
    left: 0;
    top: 0;
    text-align: left;
  }
`

export const Form = styled.form`
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767.98px) {
    margin-top: 0;
  }
`

export const FormInput = styled.input`
  background-color: rgba(0, 0, 0, 0);
  color: #99cb34;
  display: block;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid rgba(245, 245, 245, 0.1);
  height: 85px;
  margin: -1px auto;
  padding: 10px 10%;
  position: relative;
  transition-duration: 0.2s;
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: 0;

  @media (max-width: 1199.98px) {
    text-align: center;
  }
  @media (max-width: 991.98px) {
    padding: 10px 5%;
    text-align: center;
    height: 60px;
  }
  @media (max-width: 767.98px) {
    padding: 10px 10%;
    width: 100%;
    text-align: left;
    height: 65px;
  }

  &:focus {
    border-bottom: 1px solid #99cb34;
    color: rgba(255, 255, 255, 1);
    transition-duration: 0.2s;
  }
`

export const NameInput = styled(FormInput)`
  border-top: 0 !important;
  padding: 10px 10% 10px 12%;
  text-align: left;
  width: 43%;
  @media (max-width: 1199.98px) {
    width: 47%;
    padding: 10px 10%;
  }
  @media (max-width: 991.98px) {
    width: 49%;
  }
  @media (max-width: 767.98px) {
    width: 100%;
  }
`

export const EmailInput = styled(FormInput)`
  width: 60%;
  @media (max-width: 1199.98px) {
    width: 70%;
  }
  @media (max-width: 767.98px) {
    width: 100%;
  }
`

export const WebsiteInput = styled(FormInput)`
  width: 80%;
  @media (max-width: 1199.98px) {
    width: 94%;
  }
  @media (max-width: 767.98px) {
    width: 100%;
  }
`

export const SubjectInput = styled(FormInput)`
  width: 100%;
`

export const MessageInput = styled.textarea`
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(245, 245, 245, 0.1);
  border-top: 0;
  color: #fff;
  border-right: 0;
  border-left: 0;
  min-height: 100px;
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;
  font-weight: 400;
  max-height: 450px;
  padding: 30px 4%;
  width: 100%;
  outline: 0;
  resize: vertical;
  &:focus {
    border-bottom: 1px solid #99cb34;
    color: rgba(255, 255, 255, 1);
    transition-duration: 0.2s;
  }
  @media (max-width: 1199.98px) {
    text-align: center;
    width: 100%;
    padding: 15px 4%;
  }
  @media (max-width: 767.98px) {
    text-align: left;
    padding: 18px 10%;
  }
`

export const SubmitButton = styled.button`
  background-color: rgba(0, 0, 0, 0);
  background-image: url(${(p) => p.rhomb.file.url});
  background-position: left;
  border: 0 none;
  bottom: 27.5%;
  color: #fff;
  font-family: 'Exo 2', sans-serif;
  font-size: 24px;
  font-weight: 900;
  height: auto;
  letter-spacing: 3px;
  max-width: 260px;
  min-height: 260px;
  opacity: 0.8;
  position: absolute;
  right: -21.5%;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 1199.98px) {
    bottom: 0;
    font-size: 20px;
    width: 100%;
    max-width: 100%;
    background-image: none;
    padding: 9px;
    margin: 10px auto 0;
    background-color: #99cb34;
    min-height: auto;
    position: relative;
    right: 0;
  }
  @media (max-width: 767.98px) {
    bottom: 0;
    max-width: 200px;
    min-height: auto;
    position: relative;
    float: right;
    padding: 16px;
    margin-right: 25px;
    margin-top: 20px;
    right: 0;
    background-color: #99cb34;
    background-image: none;
    width: 100%;
  }
`
