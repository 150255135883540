import styled from 'styled-components'
import { Img } from '../../common'

export const StatisticsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-around;
  position: relative;
  width: 100%;
`

export const BackgroundImage = styled(Img)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const StatisticImage = styled(Img)`
  width: 85px;
  height: 85px;
`
export const Stuff = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  justify-content: center;
  text-align: center;
  z-index: 0;
  margin: 0 auto;
  position: relative;
`

export const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: 46px;
  font-weight: 800;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.1;
  @media (max-width: 767.98px) {
    font-size: 36px;
  }
`

export const BlocksContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 50px;
`

export const Block = styled.div`
  display: inline-block;
  float: none;
  margin: 45px 0;
  max-width: 280px;
  padding: 0 15px;
  text-align: center;
  position: relative;
  width: 100%;
  @media (max-width: 991.98px) {
    max-width: 250px;
  }
  @media (max-width: 767.98px) {
    min-width: 50%;
  }
`

export const CounterTitle = styled.p`
  color: #9ecf31;
  font-family: 'Exo 2', sans-serif;
  font-size: 55px;
  font-weight: 900;
  margin-top: 20px;
  margin-bottom: 10px;
  @media (max-width: 991.98px) {
    font-size: 42px;
  }
`

export const Subtitle = styled.p`
  strong,
  & {
    color: #fff;
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
    font-weight: 100;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  strong {
    font-weight: bold;
  }
`
